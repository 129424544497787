// This file was automatically generated from admin.venue.floorplanlayout.edit.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Admin == 'undefined') { Nightloop.Templates.Admin = {}; }
if (typeof Nightloop.Templates.Admin.Venue == 'undefined') { Nightloop.Templates.Admin.Venue = {}; }
if (typeof Nightloop.Templates.Admin.Venue.FloorplanLayout == 'undefined') { Nightloop.Templates.Admin.Venue.FloorplanLayout = {}; }


Nightloop.Templates.Admin.Venue.FloorplanLayout.Edit = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Admin.Layout(soy.$$augmentData(opt_data, {is_sevenrooms_admin: opt_data.content.is_sevenrooms_admin, DynamicTemplate: 'Nightloop.Templates.Admin.Venue.FloorplanLayout.EditBit'}), output);
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Admin.Venue.FloorplanLayout.EditBit = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div id="page-admin-venue-floorplanlayout-edit"><div class="images-upload-area"><div><a href="/admin/venue/', soy.$$escapeHtml(opt_data.content.venue.id), '/edit" class="ajaxify">&lt;&lt; back to venue edit</a></div><h3>', soy.$$escapeHtml(opt_data.content.venue.name), ' Floorplan Layouts</h3><div><div style="margin-bottom:10px;"><form action="/admin/venue/', soy.$$escapeHtml(opt_data.content.venue.id), '/floorplanlayout/add" method="post" class="ajaxify">New Layout: <input type="text" name="layout_id" value=""/>  Copy Coordinates From: <select name="copy_from_layout"><option value=\'\'>---</option>');
  var layoutList1575 = opt_data.content.layouts;
  var layoutListLen1575 = layoutList1575.length;
  for (var layoutIndex1575 = 0; layoutIndex1575 < layoutListLen1575; layoutIndex1575++) {
    var layoutData1575 = layoutList1575[layoutIndex1575];
    output.append('<option value=\'', soy.$$escapeHtml(layoutData1575.id), '\'>', soy.$$escapeHtml(layoutData1575.name), '</option>');
  }
  output.append('</select> <input type="submit" value="Add"/></form></div><div style="margin: 10px 0"><div>Select Layout to Edit:</div><ul>');
  var layoutList1583 = opt_data.content.layouts;
  var layoutListLen1583 = layoutList1583.length;
  for (var layoutIndex1583 = 0; layoutIndex1583 < layoutListLen1583; layoutIndex1583++) {
    var layoutData1583 = layoutList1583[layoutIndex1583];
    output.append('<li><a href="/admin/venue/', soy.$$escapeHtml(opt_data.content.venue.id), '/floorplanlayout/edit?floorplan_layout_id=', soy.$$escapeHtml(layoutData1583.id), '" class="ajaxify">', soy.$$escapeHtml(layoutData1583.name), '</a></li>');
  }
  output.append('</ul></div>');
  if (opt_data.content.layout_to_render) {
    var floorplan_layout__soy1595 = opt_data.content.layout_to_render;
    output.append('<div style="border: 1px solid #666; padding: 10px;"><div style="font-weight: bolder;font-size: 16px;padding:10px;margin-bottom: 9px;"><div class="float-left">', soy.$$escapeHtml(floorplan_layout__soy1595.layout.layout_id), ' layout</div><div class="float-right"><div><div class="float-left" style="margin-right:10px;">', (! floorplan_layout__soy1595.is_default) ? '<form action="/admin/venue/' + soy.$$escapeHtml(opt_data.content.venue.id) + '/floorplanlayout/delete" method="post" class="ajaxify"><input type="submit" value="Delete" onclick="return confirmPost();"/><input type="hidden" name="floorplan_layout_id" value="' + soy.$$escapeHtml(floorplan_layout__soy1595.layout.id) + '"/></form>' : '&nbsp;', '</div><div class="float-left"><input type="button" onclick="javascript:$(\'select.floorplan_room_select-', soy.$$escapeHtml(floorplan_layout__soy1595.layout.id), '\').val($($(\'select.floorplan_room_select-', soy.$$escapeHtml(floorplan_layout__soy1595.layout.id), ' option\')[1]).val());" value="Move all tables to first room"/><input type="button" onclick="javascript:$(\'select.floorplan_room_select-', soy.$$escapeHtml(floorplan_layout__soy1595.layout.id), '\').val(\'\');" value="Disable all tables"/></div></div></div><div class="float-end"></div></div>');
    if (floorplan_layout__soy1595.rollback_snapshot_keys.length) {
      output.append('<div style="border: 1px solid #666; padding: 10px;"><div style="font-weight: bold">Rollback Check Points</div><ul>');
      var rollback_snapshot_keyList1618 = floorplan_layout__soy1595.rollback_snapshot_keys;
      var rollback_snapshot_keyListLen1618 = rollback_snapshot_keyList1618.length;
      for (var rollback_snapshot_keyIndex1618 = 0; rollback_snapshot_keyIndex1618 < rollback_snapshot_keyListLen1618; rollback_snapshot_keyIndex1618++) {
        var rollback_snapshot_keyData1618 = rollback_snapshot_keyList1618[rollback_snapshot_keyIndex1618];
        output.append('<li><form action="/admin/venue/', soy.$$escapeHtml(opt_data.content.venue.id), '/floorplanlayout/edit" method="post" class="ajaxify"><input type="hidden" name="floorplan_layout_id" value="', soy.$$escapeHtml(floorplan_layout__soy1595.layout.id), '"/><input type="hidden" name="rollback_snapshot_key" value="', soy.$$escapeHtml(rollback_snapshot_keyData1618), '"/><label><input type="checkbox" name="confirm_rollback"/> check this box and then click: </label> <input type="submit" value="Rollback to ', soy.$$escapeHtml(rollback_snapshot_keyData1618), '"></form></li>');
      }
      output.append('</ul></div>');
    }
    output.append('<div style="border: 1px solid #666; padding: 10px; margin: 10px 0;"><div style="font-weight: bold">Upload a Layout</div><form action="', soy.$$escapeHtml(opt_data.content.upload_layout_url), '" enctype="multipart/form-data" method="post"><input type="hidden" name="floorplan_layout_id" value="', soy.$$escapeHtml(floorplan_layout__soy1595.layout.id), '"/><input type="file" name="floorplanlayout_psd"/><input type="submit" value="Do it"/></form></div><form action="/admin/venue/', soy.$$escapeHtml(opt_data.content.venue.id), '/floorplanlayout/edit" method="post" class="ajaxify"><input type="hidden" name="floorplan_layout_id" value="', soy.$$escapeHtml(floorplan_layout__soy1595.layout.id), '"/><div style="padding: 10px 5px"><label><input type="checkbox" name="uses_grid" value="1" ', (floorplan_layout__soy1595.uses_grid) ? 'checked=\'checked\' ' : '', ' />Hide floorplan image (use grid lines)</label></div><div style="padding: 10px 5px">Room zoom config:  <select name="room_zoom_config_state" style="width:150px;"><option value="0" ', (floorplan_layout__soy1595.room_zoom_config_state == 0) ? 'selected="selected"' : '', '>0 - legacy</option><option value="1" ', (floorplan_layout__soy1595.room_zoom_config_state == 1) ? 'selected="selected"' : '', '>1 - disable auto zoom</option><option value="2" ', (floorplan_layout__soy1595.room_zoom_config_state == 2) ? 'selected="selected"' : '', '>2 - enable auto zoom</option></select></div>');
    if (opt_data.content.is_bar_enabled) {
      output.append('<table><tr><td style="font-weight:bold;">BAR Service</td><td>room:<select name="floorplan_room_id"><option value="">-disabled-</option>');
      var floorplan_roomList1658 = opt_data.content.floorplan_rooms;
      var floorplan_roomListLen1658 = floorplan_roomList1658.length;
      for (var floorplan_roomIndex1658 = 0; floorplan_roomIndex1658 < floorplan_roomListLen1658; floorplan_roomIndex1658++) {
        var floorplan_roomData1658 = floorplan_roomList1658[floorplan_roomIndex1658];
        output.append('<option value="', soy.$$escapeHtml(floorplan_roomData1658.id), '"', (floorplan_layout__soy1595.layout.bar_config.floorplan_room_id == floorplan_roomData1658.id) ? 'selected="selected"' : '', '>', soy.$$escapeHtml(floorplan_roomData1658.code), '</option>');
      }
      output.append('</select></td><td>x: <input type="number" step="0.1" name="bar_x" value="', (! (floorplan_layout__soy1595.layout.bar_config.x_coord == 'None')) ? soy.$$escapeHtml(floorplan_layout__soy1595.layout.bar_config.x_coord) : '', '" style="width:50px;"/></td><td>y: <input type="number" step="0.1" name="bar_y" value="', (! (floorplan_layout__soy1595.layout.bar_config.y_coord == 'None')) ? soy.$$escapeHtml(floorplan_layout__soy1595.layout.bar_config.y_coord) : '', '" style="width:50px;"/></td><td>size:<input type="number" step="0.1" name="bar_size" value="', (! (floorplan_layout__soy1595.layout.bar_config.size == 'None')) ? soy.$$escapeHtml(floorplan_layout__soy1595.layout.bar_config.size) : '', '" style="width:50px;"/></td><td>rotation:<input type="number" step="0.1" name="bar_rotation" value="', (! (floorplan_layout__soy1595.layout.bar_config.rotation == 'None')) ? soy.$$escapeHtml(floorplan_layout__soy1595.layout.bar_config.rotation) : '', '" style="width:50px;"/></td><td>shape:<select name="bar_shape">');
      var shapeList1686 = opt_data.content.table_shapes;
      var shapeListLen1686 = shapeList1686.length;
      for (var shapeIndex1686 = 0; shapeIndex1686 < shapeListLen1686; shapeIndex1686++) {
        var shapeData1686 = shapeList1686[shapeIndex1686];
        output.append('<option value="', soy.$$escapeHtml(shapeData1686), '"', (shapeData1686 == floorplan_layout__soy1595.layout.bar_config.shape) ? 'selected="selected"' : '', '>', soy.$$escapeHtml(shapeData1686), '</option>');
      }
      output.append('</select></td><td><label>end chairs:<input type="checkbox" name="bar_uses_end_chairs" value="1"', (floorplan_layout__soy1595.layout.bar_config.uses_end_chairs) ? 'checked="checked"' : '', '"/></label></td></tr></table>');
    }
    output.append('<table>');
    var table_item_configList1703 = floorplan_layout__soy1595.table_item_configs;
    var table_item_configListLen1703 = table_item_configList1703.length;
    for (var table_item_configIndex1703 = 0; table_item_configIndex1703 < table_item_configListLen1703; table_item_configIndex1703++) {
      var table_item_configData1703 = table_item_configList1703[table_item_configIndex1703];
      output.append('<tr><td>', soy.$$escapeHtml(table_item_configData1703._table.item_code), '<br/>(', soy.$$escapeHtml(table_item_configData1703._table.party_size_min), '-', soy.$$escapeHtml(table_item_configData1703._table.party_size_max), 'ppl)</td><td>room:<select name="', soy.$$escapeHtml(table_item_configData1703._table.id), '##floorplan_room_id" class="floorplan_room_select-', soy.$$escapeHtml(floorplan_layout__soy1595.layout.id), '"><option value="">-disabled-</option>');
      var floorplan_roomList1715 = opt_data.content.floorplan_rooms;
      var floorplan_roomListLen1715 = floorplan_roomList1715.length;
      for (var floorplan_roomIndex1715 = 0; floorplan_roomIndex1715 < floorplan_roomListLen1715; floorplan_roomIndex1715++) {
        var floorplan_roomData1715 = floorplan_roomList1715[floorplan_roomIndex1715];
        output.append('<option value="', soy.$$escapeHtml(floorplan_roomData1715.id), '"', (table_item_configData1703.floorplan_room_id == floorplan_roomData1715.id) ? 'selected="selected"' : '', '>', soy.$$escapeHtml(floorplan_roomData1715.code), '</option>');
      }
      output.append('</select></td><td>x:<input type="number" step="0.1" name="', soy.$$escapeHtml(table_item_configData1703._table.id), '##x_coord" value="', (! (table_item_configData1703.x_coord == 'None')) ? soy.$$escapeHtml(table_item_configData1703.x_coord) : '', '" style="width:50px;"/></td><td>y:<input type="number" step="0.1" name="', soy.$$escapeHtml(table_item_configData1703._table.id), '##y_coord" value="', (! (table_item_configData1703.y_coord == 'None')) ? soy.$$escapeHtml(table_item_configData1703.y_coord) : '', '" style="width:50px;"/></td><td>size:<input type="number" step="0.1" name="', soy.$$escapeHtml(table_item_configData1703._table.id), '##size" value="', (! (table_item_configData1703.size == 'None')) ? soy.$$escapeHtml(table_item_configData1703.size) : '', '" style="width:50px;"/></td><td>rotation:<input type="number" step="0.1" name="', soy.$$escapeHtml(table_item_configData1703._table.id), '##rotation" value="', (! (table_item_configData1703.rotation == 'None')) ? soy.$$escapeHtml(table_item_configData1703.rotation) : '', '" style="width:50px;"/></td><td><label for="', soy.$$escapeHtml(table_item_configData1703._table.id), '##party_size_min">party size (min):</label><input type="number" id="', soy.$$escapeHtml(table_item_configData1703._table.id), '##party_size_min" name="', soy.$$escapeHtml(table_item_configData1703._table.id), '##party_size_min" value="', soy.$$escapeHtml(table_item_configData1703.party_size_min), '" style="width:50px;"/></td><td><label for="', soy.$$escapeHtml(table_item_configData1703._table.id), '##party_size_max">party size (max):</label><input type="number" id="', soy.$$escapeHtml(table_item_configData1703._table.id), '##party_size_max" name="', soy.$$escapeHtml(table_item_configData1703._table.id), '##party_size_max" value="', soy.$$escapeHtml(table_item_configData1703.party_size_max), '" style="width:50px;"/></td><td>shape:<select name="', soy.$$escapeHtml(table_item_configData1703._table.id), '##shape">');
      var shapeList1769 = opt_data.content.table_shapes;
      var shapeListLen1769 = shapeList1769.length;
      for (var shapeIndex1769 = 0; shapeIndex1769 < shapeListLen1769; shapeIndex1769++) {
        var shapeData1769 = shapeList1769[shapeIndex1769];
        output.append('<option value="', soy.$$escapeHtml(shapeData1769), '"', (shapeData1769 == table_item_configData1703.shape) ? 'selected="selected"' : '', '>', soy.$$escapeHtml(shapeData1769), '</option>');
      }
      output.append('</select></td><td><label>end chairs:<input type="checkbox" name="', soy.$$escapeHtml(table_item_configData1703._table.id), '##uses_end_chairs" value="True"', (table_item_configData1703.uses_end_chairs) ? 'checked="checked"' : '', '"/></label></td></tr>');
    }
    output.append('</table><div><input type="submit" value="Save Floorplan ', soy.$$escapeHtml(floorplan_layout__soy1595.layout.layout_id), '"></div></form></div>');
  }
  output.append('</div></div><script type="text/javascript">\n            function confirmPost() {\n              return confirm("Are you sure you want to delete?");\n            }\n\n            <\/script></div>');
  return opt_sb ? '' : output.toString();
};
