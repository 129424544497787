// This file was automatically generated from direct.error.page_not_found_404.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Direct == 'undefined') { Nightloop.Templates.Direct = {}; }


Nightloop.Templates.Direct.PageNotFound = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Splash.Layout(soy.$$augmentData(opt_data, {DynamicTemplate: 'Nightloop.Templates.Direct.PageNotFoundBit'}), output);
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Direct.PageNotFoundBit = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div id="page-no-access"><div id="body"><div class="splash-box"><div class="splash-box-content"><div class="header-logo"><img height="120" width="120" src="', soy.$$escapeHtml(opt_data.MEDIA_URL), 'images/SevenRoomsLogo.svg" /></div><div class="header-text">Looks like this page is off the menu.</div><div><div class="contents-text">The page you’re looking for could not be found. Please check the URL or contact us for assistance.</div><p class="button"><a class="" href="/">Go Back</a></p></div></div></div><div class="float-end"></div></div></div>');
  return opt_sb ? '' : output.toString();
};
