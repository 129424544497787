// This file was automatically generated from widget.followers.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Widget == 'undefined') { Nightloop.Templates.Widget = {}; }


Nightloop.Templates.Widget.Followers = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div class="followers-component">', (opt_data.title) ? '<div class=\'title\'>Followers</div>' : '', '<span class="frozen-followers-area">');
  var followerList14890 = opt_data.frozenFollowers;
  var followerListLen14890 = followerList14890.length;
  for (var followerIndex14890 = 0; followerIndex14890 < followerListLen14890; followerIndex14890++) {
    var followerData14890 = followerList14890[followerIndex14890];
    output.append('<span class="pic">', soy.$$escapeHtml(followerData14890.initials), '<em class=\'name\'>', soy.$$escapeHtml(followerData14890.full_name), '</em></span>');
  }
  output.append('</span><span class="followers-area">');
  var followerList14898 = opt_data.followers;
  var followerListLen14898 = followerList14898.length;
  for (var followerIndex14898 = 0; followerIndex14898 < followerListLen14898; followerIndex14898++) {
    var followerData14898 = followerList14898[followerIndex14898];
    output.append('<span class="pic">', soy.$$escapeHtml(followerData14898.initials), '<span class="del follower-remove" fid="', soy.$$escapeHtml(followerData14898.id), '">x</span>', (opt_data.includeInput) ? '<input type="hidden" name="fid" value="' + soy.$$escapeHtml(followerData14898.id) + '" />' : '', '<em class=\'name\'>', soy.$$escapeHtml(followerData14898.full_name), '</em></span>');
  }
  output.append('</span><span id="insert-followers" class="add"><img src="', soy.$$escapeHtml(opt_data.MEDIA_URL), 'images/add_2x.png" /><select id="followers-select"></select></span></div>');
  return opt_sb ? '' : output.toString();
};
