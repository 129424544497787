// This file was automatically generated from admin.venue.actuals.import.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Admin == 'undefined') { Nightloop.Templates.Admin = {}; }


Nightloop.Templates.Admin.VenueActualsImport = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Admin.Layout(soy.$$augmentData(opt_data, {is_sevenrooms_admin: opt_data.content.is_sevenrooms_admin, DynamicTemplate: 'Nightloop.Templates.Admin.VenueActualsImportBit'}), output);
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Admin.VenueActualsImportBit = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div id="page-admin-venue-actuals-import"><div class="content-header">Venue Actuals Import - ', soy.$$escapeHtml(opt_data.content.venue.name), '</div>', (opt_data.content.error_msg) ? '<div class="error-message">' + soy.$$escapeHtml(opt_data.content.error_msg) + '</div>' : '', (opt_data.content.success_msg) ? '<div class="gold-message"><span style="display:block">' + soy.$$escapeHtml(opt_data.content.success_msg) + '</span></div>' : '', '<form id="import-daily-actuals-form" method="POST" action="/admin/venue/', soy.$$escapeHtml(opt_data.content.venue.id), '/actuals/import"><div class="white-box">');
  if (opt_data.content.is_preview) {
    output.append('<input type="hidden" name="confirm" id="actuals-import-confirm-input" value="true" /><input type="hidden" name="blob_key" value="', soy.$$escapeHtml(opt_data.content.blob_key), '" /><div id="actuals-import-confirm-display"><div class="white-box-block">Please confirm the following preview:</div><div class="white-box-block"><div>Date,Name,Party Size, Booked By,Server,Type, Tables,Minimum,Arrival Time, Duration, Prepayment,Onsite Net Spend, Paid By,Check #,Notes, Client Ref,Phone,Phone Locale, Email,Birthday,Arrived Time, Left Time,Status, Created By:</div>');
    var actuals_entryList484 = opt_data.content.actualspreview;
    var actuals_entryListLen484 = actuals_entryList484.length;
    for (var actuals_entryIndex484 = 0; actuals_entryIndex484 < actuals_entryListLen484; actuals_entryIndex484++) {
      var actuals_entryData484 = actuals_entryList484[actuals_entryIndex484];
      output.append('<div>', soy.$$escapeHtml(actuals_entryData484.date_formatted_short), ',', soy.$$escapeHtml(actuals_entryData484.client_name_last_first_display), ',', soy.$$escapeHtml(actuals_entryData484.total_guests), ',', soy.$$escapeHtml(actuals_entryData484.via), ',', soy.$$escapeHtml(actuals_entryData484.served_by_name), ',', soy.$$escapeHtml(actuals_entryData484.system_class), ',', soy.$$escapeHtml(actuals_entryData484.table_codes_display), ',', soy.$$escapeHtml(actuals_entryData484.min_price_formatted), ',', soy.$$escapeHtml(actuals_entryData484.arrival_time_display), ',', soy.$$escapeHtml(actuals_entryData484.duration_display), ',', soy.$$escapeHtml(actuals_entryData484.prepayment_formatted), soy.$$escapeHtml(actuals_entryData484.final_bill_formatted_no_decimals), ',', soy.$$escapeHtml(actuals_entryData484.paid_by), ',', soy.$$escapeHtml(actuals_entryData484.check_numbers), ',', soy.$$escapeHtml(actuals_entryData484.notes), ',', soy.$$escapeHtml(actuals_entryData484.client_ref_no), ',', soy.$$escapeHtml(actuals_entryData484.phone_number_formatted), ',', soy.$$escapeHtml(actuals_entryData484.email), ',', soy.$$escapeHtml(actuals_entryData484.birthday), ',', soy.$$escapeHtml(actuals_entryData484.marked_time), ',', soy.$$escapeHtml(actuals_entryData484.left_time), ',', soy.$$escapeHtml(actuals_entryData484.status_formatted), ',', soy.$$escapeHtml(actuals_entryData484.user.full_name), ',</div>');
    }
    output.append('</div></div>');
  }
  output.append('<div id="actuals-import-preview-display" class="', (opt_data.content.is_preview) ? 'no-display' : '', '"><div class="white-box-block"><div class="float-left w65">Use this tool to add many actuals all at once. <br/>This will not override actuals already entered.</div><div class="float-end"></div></div><div class="white-box-block"><div id="actuals-batch-input-container"><div id="actuals-batch-input-prompt">date, client_first_name, client_last_name, booked_by_name, party_size, table_item_code, table_item_code2, table_item_code3, min_price, arrival_time, duration, served_by_name, prepayment, final_bill, paid_by, check_numbers, notes, client_ref_no, phone_number, phone_number_locale, email, birthday, marked_time, left_time, status, comps, num_bottles_sold, external_id, additional_table_items<br/>...</div><textarea name="actuals_batch" id="actuals-batch">', soy.$$escapeHtml(opt_data.content.actuals_batch), '</textarea></div></div></div><div class="white-box-block">');
  if (opt_data.content.is_preview) {
    output.append('<div id="actuals-import-confirm-buttons" class="float-right"><div class="link-next-to-button-div float-left"><a id="actuals-import-cancel-btn" class="close-main-colorbox-link" href="javascript:void(0);">cancel</a></div> ');
    Nightloop.Templates.Widget.GoldButton({text: 'GO BACK', size: 'medium', id: 'actuals-import-makechanges-btn'}, output);
    Nightloop.Templates.Widget.GoldButton({text: 'CONFIRM', size: 'medium', id: 'actuals-import-confirm-btn'}, output);
    output.append('</div>');
  }
  output.append('<div id="actuals-import-preview-buttons" class="float-right ', (opt_data.content.is_preview) ? 'no-display' : '', '"><div class="link-next-to-button-div float-left"><a id="actuals-import-cancel-btn" class="close-main-colorbox-link" href="javascript:void(0);">cancel</a></div> ');
  Nightloop.Templates.Widget.GoldButton({text: 'PREVIEW', size: 'medium', id: 'actuals-import-preview-btn'}, output);
  output.append('</div><div class="float-end"></div></div></div></form><script type="text/javascript">$(document).ready( function() {Pmp.Admin.Venue.Actuals.Import.initialize();});<\/script></div>');
  return opt_sb ? '' : output.toString();
};
