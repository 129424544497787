// This file was automatically generated from splash.noaccess.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Splash == 'undefined') { Nightloop.Templates.Splash = {}; }


Nightloop.Templates.Splash.NoAccess = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Splash.Layout(soy.$$augmentData(opt_data, {DynamicTemplate: 'Nightloop.Templates.Splash.NoAccessBit'}), output);
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Splash.NoAccessBit = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div id="page-no-access"><div id="body"><div class="splash-box"><div class="splash-box-content"><div class="header-logo"><img height="120" width="120" src="', soy.$$escapeHtml(opt_data.MEDIA_URL), 'images/SevenRoomsLogo.svg" /></div><div class="header-text">Looks like this page is off the menu.</div><div><div class="contents-text">', (opt_data.is_resource) ? 'You do not have access to this protected data.' : (opt_data.is_unauthorized_network) ? 'You are accessing this site from an unauthorized network.' : 'Your user account is not set up with any access.', ' Please ask your supervisor to confirm your access. If you believe you are reaching this page in error, please contact <a href="mailto:support@sevenrooms.com">support@sevenrooms.com</a></div>', (opt_data.logged_in) ? '<br><p>Logged in user: ' + soy.$$escapeHtml(opt_data.logged_in) + '</p>' : '', '<p class="button"><a class="" href="/logout">Logout</a></p></div></div></div><div class="float-end"></div></div></div>');
  return opt_sb ? '' : output.toString();
};
