// This file was automatically generated from admin.venuegroup.list.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Admin == 'undefined') { Nightloop.Templates.Admin = {}; }


Nightloop.Templates.Admin.VenueGroupList = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Admin.Layout(soy.$$augmentData(opt_data, {is_sevenrooms_admin: opt_data.content.is_sevenrooms_admin, DynamicTemplate: 'Nightloop.Templates.Admin.VenueGroupListBit'}), output);
  Nightloop.Templates.Admin.VenueGroupTableScript(null, output);
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Admin.VenueGroupListBit = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div id="venuegrouplist-page"><div class="content-header">Venue Groups</div><div class="right vpadding"><a id="venuegroup-add-new-link" class="ajaxify" href="/admin/venuegroup/create">+ create new</a></div><table id="venue-group-list-table" style="width:100%; background:#fff;" class="display nowrap"><thead><td>Name</td><td>ID</td><td class="right" width="110">Actions</td></thead><tbody>');
  var venue_groupList1447 = opt_data.content.venue_groups;
  var venue_groupListLen1447 = venue_groupList1447.length;
  for (var venue_groupIndex1447 = 0; venue_groupIndex1447 < venue_groupListLen1447; venue_groupIndex1447++) {
    var venue_groupData1447 = venue_groupList1447[venue_groupIndex1447];
    Nightloop.Templates.Admin.VenueGroupRecord(soy.$$augmentData(opt_data, {venue_group: venue_groupData1447}), output);
  }
  output.append('</tbody></table><div class="right vpadding"><a class="ajaxify" href="/admin/venuegroup/create">+ create new</a></div></div>');
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Admin.VenueGroupTableHeader = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<thead><td>Name</td><td>ID</td><td class="right" width="110">Actions</td></thead>');
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Admin.VenueGroupRecord = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<tr class="container entity-record"><td><span class="venuegroup-name-only">', soy.$$escapeHtml(opt_data.venue_group.name), '</span></td><td>', soy.$$escapeHtml(opt_data.venue_group.id), '</td><td class="right"><a href="#" onclick="Pmp.Utils.copyToClipboardWithIndicator(\'', soy.$$escapeHtml(opt_data.venue_group.id), '\', this, \'[copied]\')">[copy ID]</a> <a class="ajaxify venuegroup-edit-link" href="/admin/venuegroup/', soy.$$escapeHtml(opt_data.venue_group.id), '/edit">[edit]</a> <a class=\'ajaxify\' href=\'/admin/user/entity/', soy.$$escapeHtml(opt_data.venue_group.id), '/list\'>[users]</a></td></tr>');
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Admin.VenueGroupTableScript = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<script type="text/javascript">\n        $(document).ready(function() {\n            $(\'#content\').width(1600);\n            var table = $(\'#venue-group-list-table\').DataTable({\n                "displayLength": 100,\n                "lengthMenu": [[50, 100, 200, 500, -1], [50, 100, 200, 500, "All"]],\n            });\n            Pmp.Utils.attachDataTablesToSearch(table, \'q\')\n        });\n    <\/script>');
  return opt_sb ? '' : output.toString();
};
