// This file was automatically generated from admin.conciergegroup.list.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Admin == 'undefined') { Nightloop.Templates.Admin = {}; }


Nightloop.Templates.Admin.ConciergeGroupList = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Admin.Layout(soy.$$augmentData(opt_data, {is_sevenrooms_admin: opt_data.content.is_sevenrooms_admin, DynamicTemplate: 'Nightloop.Templates.Admin.ConciergeGroupListBit'}), output);
  Nightloop.Templates.Admin.ConciergeGroupTableScript(null, output);
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Admin.ConciergeGroupListBit = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div id="conciergegrouplist-page"><div class="content-header">Concierge Groups</div><div class="right vpadding"><a id="conciergegroup-add-new-link" class="ajaxify" href="/admin/conciergegroup/create">+ create new</a></div><table id="concierge-group-list-table" style="width:100%; background:#fff;" class="display nowrap"><thead><td>Name</td><td>ID</td><td class="right" width="110">Actions</td></thead><tbody>');
  var concierge_groupList7979 = opt_data.content.concierge_groups;
  var concierge_groupListLen7979 = concierge_groupList7979.length;
  for (var concierge_groupIndex7979 = 0; concierge_groupIndex7979 < concierge_groupListLen7979; concierge_groupIndex7979++) {
    var concierge_groupData7979 = concierge_groupList7979[concierge_groupIndex7979];
    Nightloop.Templates.Admin.ConciergeGroupRecord(soy.$$augmentData(opt_data, {concierge_group: concierge_groupData7979}), output);
  }
  output.append('</tbody></table></div>');
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Admin.ConciergeGroupTableHeader = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<thead><td>Name</td><td>ID</td><td class="right" width="110">Actions</td></thead>');
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Admin.ConciergeGroupRecord = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<tr class="container entity-record"><td><span class="conciergegroup-name-only">', soy.$$escapeHtml(opt_data.concierge_group.name), '</span></td><td>', soy.$$escapeHtml(opt_data.concierge_group.id), '</td><td class="right"><a class="ajaxify float-right conciergegroup-edit-link" href="/admin/conciergegroup/', soy.$$escapeHtml(opt_data.concierge_group.id), '/edit">[edit concierge group]</a></td></tr>');
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Admin.ConciergeGroupTableScript = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<script type="text/javascript">\n      $(document).ready(function() {\n        $(\'#content\').width(1600);\n        var table = $(\'#concierge-group-list-table\').DataTable({\n          "displayLength": 100,\n          "lengthMenu": [[50, 100, 200, 500, -1], [50, 100, 200, 500, "All"]],\n        });\n        Pmp.Utils.attachDataTablesToSearch(table, \'q\')\n      });\n    <\/script>');
  return opt_sb ? '' : output.toString();
};
