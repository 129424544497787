var Encrypt = (function () {
  var encryptor = null
  var pem_re = /-----BEGIN (?:[\w\s]+)-----\s*([0-9A-Za-z+/=\s]+)-----END/

  var _init = function (public_key) {
    var components = _parse_pub_key(public_key)
    encryptor = new RsaOaep(components[0], components[1])
  }

  var _parse_pub_key = function (public_key) {
    var pem_result = public_key
    if (public_key.indexOf('-----BEGIN') > -1) {
      pem_result = pem_re.exec(public_key)[1].replace(/\s/g, '')
    }
    var prep = window.atob(pem_result)
    var pubkey_tree = ASN1.decode(prep)

    var n_raw = pubkey_tree.sub[1].sub[0].sub[0].rawContent()
    var e_raw = pubkey_tree.sub[1].sub[0].sub[1].rawContent()

    var n = n_raw
    var e = 0
    for (var i = 0; i < e_raw.length; i++) {
      e = (e << 8) | e_raw.charCodeAt(i)
    }
    return [n, e]
  }

  var _encrypt = function (message) {
    return encryptor.encrypt(message)
  }

  var _freedomPayCard = function (cardNumber, expMonth, expYear, ccv) {
    expYear = expYear.substr(-2)
    expMonth = expMonth.length == 1 ? '0' + expMonth : expMonth
    var str = 'M' + cardNumber + '=' + expYear + expMonth + ':' + ccv
    return _encrypt(str)
  }

  return {
    init: _init,
    encrypt: _encrypt,
    freedomPayCard: _freedomPayCard,
  }
})()

var AccountTypes = {
  STRIPE: 'STRIPE',
  CYBERSOURCE: 'CYBERSOURCE',
  FREEDOMPAY: 'FREEDOMPAY',
}

var BillingService = (function () {
  var base_url = ''
  var account_type = null

  // Internal methods and data
  var _init = function (key, type) {
    account_type = type || AccountTypes.STRIPE

    switch (account_type) {
      case AccountTypes.FREEDOMPAY:
        Encrypt.init(key)
        break
      default:
        window.Stripe && window.Stripe.setPublishableKey(key)
    }

    base_url = $('#base_url').val() || (Pmp.Manager.Global ? Pmp.Manager.Global._managerBaseUrl : null)
  }

  var _isPaymentProviderSupported = function ()
  {
    return account_type === AccountTypes.STRIPE || account_type === AccountTypes.FREEDOMPAY;
  }


  var _saveTaxRates = function (args) {
    var deferred = new $.Deferred()
    $.ajax({
      url: base_url + '/bank/update_tax_rates',
      method: 'post',
      data: {
        tax_rows: args.tax_rows,
      },
      success: function (resp) {
        deferred.resolve(resp.refresh)
      },
      error: function (resp) {
        deferred.reject(resp.error)
      },
    })
    return deferred
  }

  /**
   * Arguments is an object with the following properties:
   *
   *   country         e.g. 'US'
   *   currency        e.g. 'USD'
   *   routing_number
   *   account_number
   */

  var _addBankAccountUSA = function (args) {
    var data = {
      country: 'US',
      currency: 'USD',
      routing_number: args.routing_number,
      account_number: args.account_number,
    }
    var deferred = new $.Deferred()
    Stripe.bankAccount.createToken(data, function (status, response) {
      _addBankResponse(deferred, args, status, response)
    })
    return deferred
  }

  var _addBankAccountCanada = function (args) {
    var data = {
      country: 'CA',
      currency: 'CAD',
      routing_number: args.routing_number,
      account_number: args.account_number,
    }
    var deferred = new $.Deferred()
    Stripe.bankAccount.createToken(data, function (status, response) {
      _addBankResponse(deferred, args, status, response)
    })
    return deferred
  }

  var _addBankAccountGB = function (args) {
    var data = {
      country: 'GB',
      currency: 'GBP',
      account_number: args.account_number,
    }
    var deferred = new $.Deferred()
    Stripe.bankAccount.createToken(data, function (status, response) {
      _addBankResponse(deferred, args, status, response)
    })
    return deferred
  }

  var _addBankAccountIT = function (args) {
    var data = {
      country: 'IT',
      currency: 'EUR',
      account_number: args.account_number,
    }
    var deferred = new $.Deferred()
    Stripe.bankAccount.createToken(data, function (status, response) {
      _addBankResponse(deferred, args, status, response)
    })
    return deferred
  }

  var _addBankResponse = function (deferred, args, status, response) {
    if (response.error) {
      deferred.reject(response.error)
    } else {
      $.ajax({
        url: base_url + '/bank',
        method: 'post',
        data: {
          bank_token: response.id,
        },
        success: function () {
          // TODO: determine what to pass
          deferred.resolve('success')
        },
      })
    }
  }

  /**
   * Arguments is an object with the following properties:
   *
   *   client_id		venue client id
   *   name			cardholder name
   *   number			card number
   *   exp_month		expiration month
   *   exp_year		expiration year
   *   cvc            card cvc
   */
  var _addCard = function (args, stripe_obj) {
    var data = {
      name: args.name,
      number: args.number,
      exp_month: args.exp_month,
      exp_year: args.exp_year,
      cvc: args.cvc,
    }
    var deferred = new $.Deferred()

    if (account_type == AccountTypes.FREEDOMPAY) {
      _getFreedomPayToken(args.number, args.exp_month, args.exp_year, args.cvc).done(function (token) {
        if (args.client_id) {
          _addCardResponse(deferred, args, token)
        } else {
          deferred.resolve(token)
        }
      })
    } else if (account_type == AccountTypes.STRIPE) {
      _getSetupIntent(stripe_obj.stripe, stripe_obj.card, args.name, args.noConnect).then(
        function (response) {
          if (args.client_id) {
            _addCardResponse(deferred, args, response)
          } else {
            deferred.resolve(response)
          }
        },
        function (response) {
          deferred.reject(response)
        }
      )
      //Stripe.card.createToken(data, function(status, response) {
      //  if (response.error) {
      //    deferred.reject(response.error);
      //  } else if (args.client_id) {
      //    _addCardResponse(deferred, args, response.id);
      //  } else {
      //    deferred.resolve(response.id)
      //  }
      //});
    } else {
      deferred.reject('This provider does not support adding cards.')
    }

    return deferred
  }

  var _addCardResponse = function (deferred, args, token) {
    $.ajax({
      url: base_url + '/billing/' + args.client_id + '/add_card',
      method: 'post',
      data: {
        token: token,
        actual_id: args.actual_id,
      },
      success: function (resp) {
        if (resp.payload.message == 'Error') {
          deferred.reject('There was an error with the card.')
          return
        }
        deferred.resolve(resp.payload.card_id)
      },
    })
  }

  var _addCardToReservation = function (name, token, actual_id) {
    var deferred = new $.Deferred()
    $.ajax({
      url: base_url + '/billing/add_card_to_reservation',
      method: 'post',
      data: {
        name: name,
        token: token,
        actual_id: actual_id,
      },
      success: function (resp) {
        deferred.resolve(resp)
      },
    })
    return deferred
  }

  var _removeCardFromReservation = function (actual_id, card_id) {
    var deferred = new $.Deferred()
    $.ajax({
      url: base_url + '/billing/remove_card_from_reservation',
      method: 'post',
      data: {
        actual_id: actual_id,
        card_id: card_id,
      },
      success: function (resp) {
        deferred.resolve(resp)
      },
    })
    return deferred
  }

  var _listCards = function (client_id) {
    var deferred = new $.Deferred()
    $.ajax({
      url: base_url + '/billing/' + client_id + '/list_cards',
      method: 'get',
      success: function (resp) {
        var cards = resp.payload.cards
        deferred.resolve(cards)
      },
    })
    return deferred
  }

  var _removeCard = function (client_id, card_id, create_history, actual_id, venue_id) {
    var deferred = new $.Deferred()
    $.ajax({
      url: base_url + '/billing/' + client_id + '/delete_card/' + card_id,
      data: {
        create_history: create_history,
        actual_id: actual_id,
        venue_id: venue_id,
      },
      method: 'post',
      success: function (resp) {
        deferred.resolve('success')
      },
    })
    return deferred
  }

  var _listCharges = function (client_id, entity_id) {
    var deferred = new $.Deferred()
    if (!client_id) {
      deferred.resolve({ charges: [], cursor: null, more: false })
      return deferred
    }
    var params = entity_id ? '?entity_id=' + entity_id : ''
    $.ajax({
      url: base_url + '/billing/charges/' + client_id + params,
      method: 'GET',
      success: function (resp) {
        deferred.resolve({ charges: resp.charges, cursor: resp.cursor, more: resp.more })
      },
    })
    return deferred
  }

  var _listActualCharges = function (actual_id) {
    var deferred = new $.Deferred()
    if (!actual_id) {
      deferred.resolve({ charges: [], cursor: null, more: false })
      return deferred
    }
    $.ajax({
      url: base_url + '/billing/entity_charges/' + actual_id,
      method: 'GET',
      success: function (resp) {
        deferred.resolve({ charges: resp.charges, cursor: resp.cursor, more: resp.more })
      },
    })
    return deferred
  }

  var _listPayments = function (params) {
    var deferred = new $.Deferred()
    var params = params ? '?' + $.param(params) : ''
    $.ajax({
      url: base_url + '/billing/charges' + params,
      method: 'GET',
      success: function (resp) {
        deferred.resolve({
          charges: resp.charges,
          cursor: resp.cursor,
          more: resp.more,
          is_superuser: resp.is_superuser,
        })
      },
    })
    return deferred
  }

  var _refund = function (client_id, actual_id, charge_id, amount, reason) {
    var data = 'charge_id=' + encodeURIComponent(charge_id)
    data += '&actual_id=' + actual_id
    if (amount) {
      data += '&amount=' + amount
    }
    if (reason) {
      data += '&reason=' + reason
    }

    return $.ajax({
      url: base_url + '/billing/' + client_id + '/refund',
      method: 'POST',
      data: data,
      processData: false,
    })
  }

  var _chargeReservation = function (amount, base_amount, gratuity, tax, card_id, token, reservation_id, venue_group_client_id, notes) {
    var deferred = new $.Deferred()
    $.ajax({
      url: base_url + '/billing/' + venue_group_client_id + '/charge',
      method: 'POST',
      data: {
        card_id: card_id,
        token: token,
        amount: amount,
        base_amount: base_amount,
        gratuity: gratuity,
        tax: tax,
        notes: notes,
        reservation_id: reservation_id,
      },
      success: function (resp) {
        deferred.resolve(resp)
      },
      error: function (resp) {
        var error_json = $.parseJSON(resp.responseText)
        deferred.reject(error_json.message)
      },
    })
    return deferred
  }

  var _getToken = function (name, number, exp_month, exp_year, cvc) {
    switch (account_type) {
      case AccountTypes.STRIPE:
        return _getStripeToken(name, number, exp_month, exp_year, cvc)
      case AccountTypes.FREEDOMPAY:
        return _getFreedomPayToken(number, exp_month, exp_year, cvc)
      default:
        return 'Improper account setup'
    }
  }

  var _getFreedomPayToken = function (number, exp_month, exp_year, cvc) {
    var deferred = new $.Deferred()
    var _token = Encrypt.freedomPayCard(number, exp_month, exp_year, cvc)
    var token = encodeURIComponent(_token)
    deferred.resolve(token)
    return deferred
  }

  var _getStripeToken = function (name, number, exp_month, exp_year, cvc) {
    var deferred = new $.Deferred()
    Stripe.card.createToken(
      {
        name: name,
        number: number,
        exp_month: exp_month,
        exp_year: exp_year,
        cvc: cvc,
      },
      function (status, response) {
        if (status == 200) {
          deferred.resolve(response.id)
        } else {
          deferred.resolve(null)
        }
      }
    )

    return deferred
  }

  var _getPaymentIntent = function (stripe, card, name, amount) {
    var deferred = new $.Deferred()

    var url = '/booking/widget/' + Pmp.Manager.Global._url_key_or_id + '/payment_intent'
    var form = new FormData()
    form.append('amount', amount)
    form.append('source', 'SU5URVJOQUw=')
    fetch(url, {
      body: form,
      method: 'POST',
      credentials: 'same-origin',
    })
      .then(function (response) {
        return response.json()
      })
      .then(function (response) {
        var additional_data = { billing_details: { name: name } }
        return stripe.handleCardPayment(response.payload.client_secret, card, {
          payment_method_data: additional_data,
        })
      })
      .then(function (response) {
        if (response.error) {
          deferred.reject(response.error.message)
        } else {
          deferred.resolve(response.paymentIntent.id)
        }
      })

    return deferred
  }

  var _getSetupIntent = function (stripe, card, name, noConnect) {
    var deferred = new $.Deferred()

    var url = '/booking/widget/' + Pmp.Manager.Global._url_key_or_id + '/setup_intent'
    var form = new FormData()

    if (noConnect) {
      form.append('no_connect', true)
    }
    fetch(url, {
      body: form,
      method: 'POST',
      credentials: 'same-origin',
    })
      .then(function (response) {
        return response.json()
      })
      .then(function (response) {
        return stripe.confirmCardSetup(response.client_secret, {
          payment_method: {
            card: card,
            billing_details: {
              name: name,
            },
          },
        })
      })
      .then(function (response) {
        if (response.error) {
          deferred.reject(response.error.message)
        } else {
          deferred.resolve(response.setupIntent.id)
        }
      })
      .catch(function (e) {
        var error = 'Exception occurred while confirming card setup: \n' + e.message.toString() + '\n\n' + e.stack.toString()
        console.error(error)
      })

    return deferred
  }

  var _getInvoiceByChargeId = function (charge_id, actual_id) {
    var deferred = new $.Deferred()
    if (!charge_id) {
      return deferred
    }
    $.ajax({
      url: `${base_url}/billing/invoices/${charge_id}`,
      method: 'GET',
      data: {
        actual_id: actual_id,
      },
      success: function (resp) {
        deferred.resolve(resp)
      },
      error: function (resp) {
        var error_json = $.parseJSON(resp.responseText)
        deferred.reject(error_json.message)
      },
    })
    return deferred
  }

  var _requestCard = function (args) {
    var deferred = new $.Deferred()
    $.ajax({
      url: base_url + '/billing/request_card',
      method: 'POST',
      data: args,
      success: function (resp) {
        deferred.resolve(resp)
      },
      error: function (resp) {
        deferred.reject()
      },
    })

    return deferred
  }

  var _requestPayment = function (args) {
    var deferred = new $.Deferred()

    $.ajax({
      url: base_url + '/billing/request_payment',
      method: 'POST',
      data: args,
      success: function (resp) {
        deferred.resolve(resp)
      },
      error: function (resp) {
        deferred.reject()
      },
    })

    return deferred
  }

  var _removeLink = function (history_id) {
    var deferred = new $.Deferred()

    $.ajax({
      url: base_url + '/billing/remove_link',
      method: 'POST',
      data: {
        history_id: history_id,
      },
      success: function (resp) {
        deferred.resolve(resp)
      },
      error: function (resp) {
        deferred.reject()
      },
    })

    return deferred
  }

  // Export public API
  return {
    init: _init,
    addCard: _addCard,
    removeCard: _removeCard,
    addCardToReservation: _addCardToReservation,
    removeCardFromReservation: _removeCardFromReservation,
    listCards: _listCards,
    listCharges: _listCharges,
    listActualCharges: _listActualCharges,
    refund: _refund,
    listPayments: _listPayments,
    addBankAccountUSA: _addBankAccountUSA,
    addBankAccountCanada: _addBankAccountCanada,
    addBankAccountGB: _addBankAccountGB,
    addBankAccountIT: _addBankAccountIT,
    getToken: _getToken,
    getPaymentIntent: _getPaymentIntent,
    getSetupIntent: _getSetupIntent,
    chargeReservation: _chargeReservation,
    getInvoiceByChargeId: _getInvoiceByChargeId,
    saveTaxRates: _saveTaxRates,
    requestCard: _requestCard,
    requestPayment: _requestPayment,
    removeLink: _removeLink,
    isPaymentProviderSupported: _isPaymentProviderSupported
  }
})()
