// This file was automatically generated from direct.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Direct == 'undefined') { Nightloop.Templates.Direct = {}; }


Nightloop.Templates.Direct.Layout = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div id="direct"><div id="nav"><div id="nav-area"><div><div id="logo" class="float-left"><a href="/"><img src="', soy.$$escapeHtml(opt_data.MEDIA_URL), 'images/SevenRoomsLogoWhite.svg" height="33" /></a></div><div class="float-end"></div></div></div></div><div id="content-container"><div id="content">');
  Closure.Util.InsertTemplate(opt_data, output);
  output.append('</div></div></div>');
  return opt_sb ? '' : output.toString();
};
