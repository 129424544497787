var LazyActualsService = (function() {
    var _manager_base_url;
    var _currentday_urlparam;
    var _completion_callback;
    var _actuals;
    var _lazyLock = false;

    var initialize = function(manager_base_url, currentday_urlparam) {
        _manager_base_url = manager_base_url;
        _currentday_urlparam = currentday_urlparam;
        _reset();
    };

    var clear = function() {
        console.log('LazyActualsService.clear');
        _reset();
    };

    var isLoading = function() {
        console.log('LazyActualsService.isLoading');
        return _lazyLock;
    };

    var _reset = function() {
        _actuals = [];
        _completion_callback = undefined;
    }

    var lazyLoadActuals = function(shift_persistent_id, completion_callback, include_canceled, shift_reporting_period=null) {
        console.log('LazyActualsService.lazyLoadActuals');
        if (_lazyLock) {
            console.log('_lazyLock set.  Skipping load');
            return false;
        }
        _lazyLock = true;
        _reset();
        _completion_callback = completion_callback;

        var venueKey = _manager_base_url.replace('/manager/', '');
        var url = '/api-yoa/actuals/day?date=' + _currentday_urlparam + '&venue=' + venueKey;
        if (shift_persistent_id) {
            url += '&shift_persistent_id=' + shift_persistent_id;
        }
        if (include_canceled) {
          url += '&include_canceled=1';
        }
        if (shift_reporting_period) {
            url += '&shift_reporting_period=' + shift_reporting_period;
        }
        Pmp.Client.AsyncGet(
            url,
            function(data) { _receiveCachedActuals(data.data.actuals, data.data.success); }
        );
        return true;
    };

    var _receiveCachedActuals = function (actuals, success) {
      _lazyLock = false;
      _actuals = actuals;
      _completion_callback(actuals, success);
    };

    // Export public API
    return {
        initialize: initialize,
        clear: clear,
        isLoading: isLoading,
        lazyLoadActuals: lazyLoadActuals,
        notReadyMessage: 'Sorry, your reservation list is large and we\'re still preparing it. Please refresh in a few minutes and try again.'
    };
})();
