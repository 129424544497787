// This file was automatically generated from direct.3d.redirection.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.DiningWidget == 'undefined') { Nightloop.Templates.DiningWidget = {}; }


Nightloop.Templates.DiningWidget.ThreeDSecure = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<head><meta name="robots" content="noindex" /></head><body style="background:white"><img style="width:100px; height:100px; position:absolute; left:50%; margin-left:-50px; top: 50%; margin-top:-50px;" src="', soy.$$escapeHtml(opt_data.MEDIA_URL), 'images/SevenRoomsLoader.svg" /><form id="threed-secure" action="', soy.$$escapeHtml(opt_data.url), '"><input method="post" type="hidden" name="requestParameter" value="', soy.$$escapeHtml(opt_data.data), '"></form><script>document.getElementById(\'threed-secure\').submit();<\/script></body>');
  return opt_sb ? '' : output.toString();
};
